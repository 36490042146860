<script setup>
import { useRouter } from 'vue-router'
import { getCurrentInstance, ref, computed, onMounted } from 'vue'
import { useUserStore } from '@/stores/user.js'
import { generateFingerprint } from '@/utils/utils.js'
import { useConfigStore } from '@/stores/config.js'
import UiButtonYellow from '@/components/UiButtonYellow.vue'
import UiButtonBlack from '@/components/UiButtonBlack.vue'
import UiInput from '@/components/UiInput.vue'
import UiLoading2 from '@/components/UiLoading2.vue'
import { useCommonStore } from '@/stores/common.js'

const { showNotifyMessage } = useCommonStore()
const commonStore = useCommonStore()
const configStore = useConfigStore()
const emits = defineEmits(['close'])
const router = useRouter()
const goView = (path) => {
  router.push(path)
  emits('close')
}
const username = ref('')
const password = ref('')
const instance = getCurrentInstance()

const usernameRef = ref(null)
const passwordRef = ref(null)
const $t = instance.appContext.config.globalProperties.$t
const userStore = useUserStore()
const invalidTip = ref('')
const requesting = ref(false)

const login = async () => {
  let isOk = true
  isOk = usernameRef.value.validate() && isOk
  isOk = passwordRef.value.validate() && isOk
  if (!isOk || requesting.value) {
    return
  }

  requesting.value = true
  userStore
    .login({
      account: username.value,
      password: password.value,
      platform: 'PC',
      fingerprint: await generateFingerprint(),
      domain: window.location.hostname,
    })
    .then(() => {
      // goView('/')
    })
    .catch((err) => {
      console.log(err)

      showNotifyMessage('error', $t(`errorMessage${err.data.status}`))
    })
    .finally(() => {
      requesting.value = false
    })
}

function onRegister() {
  router.push('/register2')
}

function onClose() {
  if (requesting.value) {
    return
  }

  if (configStore.mustLogin) {
    return
  }
  emits('close')
}

const computedCanRegister = computed(() => {
  return useCommonStore().siteSettings.can_register
})
</script>

<template>
  <Teleport to="body">
    <div class="modal-overlay" @click="onClose">
      <div class="modal-container" @click.stop>
        <ui-loading2 v-if="requesting" />

        <div class="modal-header">
          <button class="close-button" @click="onClose">×</button>
        </div>

        <div class="logo-container">
          <img src="@/assets/img/logo/IKEALogo.png" alt="IKEA" />
        </div>
        <form @submit.prevent="login" class="login-form">
          <div class="form-group">
            <ui-input ref="usernameRef" v-model="username" label="ID" placeholder="ID" required />
          </div>
          <div class="form-group">
            <ui-input
              ref="passwordRef"
              v-model="password"
              label="Password"
              placeholder="Password"
              required
              :isPassword="true"
              :showPasswordToggle="true"
            />
          </div>

          <div class="btns">
            <ui-button-black @click="login" class="btn-login">
              {{ $t('login') }}
            </ui-button-black>

            <p class="orline" v-if="computedCanRegister">
              <span class="line"></span>
              <span>OR</span>
              <span class="line"></span>
            </p>

            <ui-button-yellow v-if="computedCanRegister" @click="onRegister" class="btn-register">
              {{ $t('register') }}
            </ui-button-yellow>

            <div class="login-tip">
              <div class="login-tip-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8.00042 0C3.58148 0 4.3409e-08 3.58148 4.3409e-08 7.99958C-0.000109406 9.05024 0.206753 10.0906 0.608773 11.0613C1.01079 12.032 1.60009 12.914 2.34302 13.657C3.08595 14.3999 3.96796 14.9892 4.93866 15.3912C5.90936 15.7932 6.94976 16.0001 8.00042 16C12.4177 16 16 12.4185 16 7.99958C16 3.58148 12.4177 0 8.00042 0ZM8.74704 2.88818C9.527 2.88818 9.75616 3.34066 9.75616 3.85813C9.75616 4.50393 9.23952 5.1014 8.35707 5.1014C7.61877 5.1014 7.26712 4.73059 7.28879 4.11645C7.28879 3.59898 7.72126 2.88818 8.74704 2.88818ZM6.74882 12.791C6.21551 12.791 5.82636 12.4669 6.19884 11.0461L6.80965 8.52539C6.91547 8.12208 6.93297 7.96042 6.80965 7.96042C6.65049 7.96042 5.95802 8.23874 5.54971 8.51372L5.28389 8.07791C6.57966 6.99547 8.06958 6.3605 8.70705 6.3605C9.24035 6.3605 9.32868 6.99047 9.06286 7.96042L8.3629 10.6103C8.23874 11.0786 8.29207 11.2402 8.41623 11.2402C8.57622 11.2402 9.09953 11.0469 9.6145 10.6411L9.91615 11.0461C8.65538 12.306 7.28129 12.791 6.74882 12.791Z"
                    fill="#F2D058"
                  />
                </svg>
              </div>

              <span class="login-tip-text">
                {{ $t('loginTip') }}
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.terms-container {
  position: relative;
  width: 80%;
  height: 80%;
  overflow-y: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overscroll-behavior: contain;
}

.modal-container {
  background: linear-gradient(0deg, #002a48 -139.06%, #0065ae 100%);
  width: 600px;
  padding: 30px 70px;

  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0;
  gap: 15px;
}

.logo-container {
  padding: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.modal-header {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  border-bottom: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0;

  h3 {
    color: #ffd600;
    font-size: 20px;
    margin: 0;
  }

  .close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.btns {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  align-items: center;

  .btn-login {
    width: 326px;
  }

  .orline {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    height: 16px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;

    .line {
      width: 128px;
      height: 1px;
      background-color: #fff;
    }
  }

  .btn-register {
    width: 326px;
    height: 50px;
  }
}

.login-tip {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;

  .login-tip-icon {
    width: 16px;
    height: 16px;
  }

  .login-tip-text {
    color: #fff;
    font-size: 13px;
    font-style: italic;
    margin-top: 10px;
  }
}

.modal-content {
  overflow-y: auto;
  flex: 1;
  height: 100%;
  background: transparent;
  border-radius: 0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: #004577;
    border-radius: 0;

    &:hover {
      background-color: #005898;
    }
  }
}
</style>
