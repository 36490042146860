<template>
  <a class="ui-button" :style="style">
    <slot />
    <ui-loading2 v-if="loading" />
  </a>
</template>

<script setup>
import UiLoading2 from '@/components/UiLoading2.vue'
import { computed } from 'vue'

const props = defineProps({
  color: {
    type: String,
    default: '#fff',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  radius: {
    type: Number,
    default: 5,
  },
})

const style = computed(() => {
  const style = {}
  if (props.radius) style.borderRadius = props.radius + 'px'
  if (props.color) style.color = props.color
  return style
})
</script>

<style scoped lang="scss">
$bg-color: #217dc9;
$border-color: #217dc9;
$hover-bg-color: #2689db;

.ui-button {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 15px 20px;
  background-color: $bg-color;
  box-sizing: border-box;
  border-radius: 3px;
  // border: 2px solid $border-color;
  position: relative;
  font-weight: bold;

  &:hover {
    background-color: $hover-bg-color;
  }
}
</style>
