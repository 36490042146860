<!--Start of Tawk.to Script-->
<script type="text/javascript">
// var Tawk_API = Tawk_API || {},
//   Tawk_LoadStart = new Date()
// ;(function () {
//   var s1 = document.createElement('script'),
//     s0 = document.getElementsByTagName('script')[0]
//   s1.async = true
//   s1.src = 'https://embed.tawk.to/66e501a050c10f7a00a9b5fa/1i7n8snqe'
//   s1.charset = 'UTF-8'
//   s1.setAttribute('crossorigin', '*')
//   s0.parentNode.insertBefore(s1, s0)
// })()
</script>
<!--End of Tawk.to Script-->

<script setup>
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import { getCurrentInstance, onMounted, ref, onUnmounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BottomFooter from '@/components/BottomFooter/BottomFooter.vue'
import { useConfigStore } from '@/stores/config.js'
import { useUserStore } from '@/stores/user.js'
import axios from 'axios'
import { getUnreadMessageCount } from '@/api/messages.js'
import { getToken } from '@/utils/token.js'
import { userHeartbeat } from '@/api/user.js'
import { generateFingerprint, getUrlParams, isMobile, checkVPN } from '@/utils/utils.js'
import { addAgentFingerprintAPI, getFingerprintAPI } from '@/api/fingerprint.js'
import Notify from '@/components/Notify/Notify.vue'
import ConfirmDialog from '@/components/ConfirmDialog/ConfirmDialog.vue'
import { useCommonStore } from '@/stores/common.js'
import * as Sentry from '@sentry/vue'
import config from '@/config.js'
import { getSiteSettingAPI } from '@/api/common.js'
import { getMessageMustList } from '@/api/messages.js'
import MessageMustDialog from '@/components/MessageMustDialog.vue'

//判断是不是vpn用户
// onMounted(() => {
//   // 初次加载时检查一次
//   checkVPN();
//
//   // 设置定时器，每隔 5 分钟检查一次
//   const intervalId = setInterval(checkVPN, 300000);
//
//   // 在组件卸载时清除定时器
//   onUnmounted(() => {
//     clearInterval(intervalId);
//   });
// });

// 网站维护数据
const maintenStatus = ref('')
let intervalId = null // 定时器的ID
useConfigStore().mustLogin = false

const fetchStatus = async () => {
  try {
    const response = await axios.get('https://prod-res-btz.link-api.com/status')
    maintenStatus.value = response.data
    console.log('状态', maintenStatus.value)
    if (maintenStatus.value === 0) {
      router.push('/Maintenance')
      useConfigStore().ismainatence = true
      configStore.showLoginDialog = false
    } else {
      if (useConfigStore().ismainatence) {
        router.push('/')
      }
      useConfigStore().ismainatence = false
    }
  } catch (error) {
    maintenStatus.value = '数据加载失败'
  }
}

// 组件挂载时设置定时器
onMounted(() => {
  const userStore = useUserStore()
  Sentry.setUser({
    id: userStore.id,
    username: userStore.account,
  })

  fetchStatus() // 初始请求
  intervalId = setInterval(fetchStatus, 60000)
})

if (isMobile() && window.location.pathname !== '/mobile/') {
  location.href = '/mobile/'
}
const router = useRouter()
router.beforeEach(async (to, from, next) => {
  if (to.name === 'register2') {
    while (!commonStore.isSiteSettingLoaded) {
      await sleep(50)
    }

    if (!commonStore.siteSettings.can_register) {
      router.replace('/')
      return
    }
  }

  if (to.path === '/') {
    useCommonStore().showLoginDialog = isNeedLogin()
  }
  if (to.name === 'maintenance') {
    configStore.showLoginDialog = false
  }

  requestMessageMustList(to.name)

  showHeader.value = to.name !== 'gameIFrame' && !isNeedLogin()
  next()
})
const configStore = useConfigStore()

const instance = getCurrentInstance()
const i18n = instance.appContext.config.globalProperties.$i18n
i18n.locale = configStore.language

const showHeader = computed(() => {
  let display = true
  display = display && router.currentRoute.value.name !== 'gameIFrame'
  display = display && router.currentRoute.value.path !== '/register2'
  display = display && router.currentRoute.value.path !== '/terms-and-conditions'
  display = display && router.currentRoute.value.name !== '/maintenance'
  return display
})

function isTokenEmpty() {
  const token = getToken()
  return token === null || token === ''
}
function isNeedLogin() {
  return configStore.mustLogin && isTokenEmpty() && !useConfigStore().ismainatence
}

const code = ref('')
const fingerprint = ref('')
onMounted(async () => {
  useUserStore().intervalFunction()
  useCommonStore().showLoginDialog = isNeedLogin()

  // 获取url中的code，并保存，如果没有则获取指纹

  const params = getUrlParams(location.href)
  fingerprint.value = await generateFingerprint()
  if (params.code) {
    code.value = params.code
    localStorage.setItem('code', code.value)
  } else if (localStorage.getItem('code')) {
    code.value = localStorage.getItem('code')
  } else {
    try {
      await getFingerprintAPI(fingerprint.value).then(({ data }) => {
        if (data.data.agent_code !== null) {
          code.value = data.data.agent_code
          localStorage.setItem('code', code.value)
        }
      })
    } catch {
      console.error('getFingerprintAPI load fail')
    }
  }
  if (code.value) {
    await addAgentFingerprintAPI({ fingerprint: fingerprint.value, agent_code: code.value })
  }

  commonStore.code = code.value
  commonStore.fingerprint = fingerprint.value
  // 获取站点设置
  await requestSiteSetting()
})

function hideTelegram() {
  document.querySelector('.telegram-wrapper').style.display = 'none'
}

const openTelegram = () => {
  window.open(config.telegram)
}

const commonStore = useCommonStore()
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
async function requestSiteSetting() {
  try {
    const { data } = await getSiteSettingAPI(commonStore.code ? commonStore.code : '')
    commonStore.siteSettings = data.data ? data.data : {}
    commonStore.isSiteSettingLoaded = true
  } catch {
    await sleep(1000)
    await requestSiteSetting()
  }
}

// 拉取下一个必读的消息 ------------------------------------------------------------
const messageMustRef = ref(null)
let messageMustIndex = -1
let messageMustList = []
function requestMessageMustList(toName) {
  // 未登录不显示必读消息
  if (!getToken()) {
    return
  }

  // 不显示必读消息的页面
  const routeNames = ['bti-sports', 'bt1-sports', 'sbo-sports', 'digitain', 'gameIFrame', 'betconstruct']
  if (routeNames.includes(toName)) {
    return
  }

  // 获取必读消息
  getMessageMustList().then(({ data }) => {
    console.log('data', data)
    messageMustList = data.data.messages
    messageMustIndex = 0
    messageMustRef.value.show()
  })
}

function getNextMessage() {
  return messageMustList[messageMustIndex++]
}
</script>

<template>
  <top-header v-if="showHeader" />
  <RouterView />
  <bottom-footer v-if="showHeader" />
  <Notify />
  <ConfirmDialog />
  <div class="telegram-wrapper">
    <div class="telegram-tooltip">{{ $t('telegram_contact_tip') }}</div>
    <img src="@/assets/img/icons/telegram.webp" alt="telegram" class="telegram-icon" @click="openTelegram" />
    <div class="telegram-close-btn" @click="hideTelegram">×</div>
  </div>
  <MessageMustDialog ref="messageMustRef" :getNextMessage="getNextMessage" />
</template>

<style scoped>
.telegram-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  opacity: 0.6;
  transition: all 0.1s ease;
}

.telegram-wrapper:hover {
  opacity: 1;
}

.telegram-wrapper:hover .telegram-close-btn,
.telegram-wrapper:hover {
  opacity: 1;
  visibility: visible;
}

.telegram-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  background: #ffffff;
  color: rgb(0, 0, 0);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  transition: all 0.2s ease;
  pointer-events: none;
}

.telegram-tooltip::after {
  content: '';
  position: absolute;
  bottom: -5px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #ffffff;
}

.telegram-close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.telegram-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.telegram-close-btn:hover {
  color: #ff0000;
  transform: scale(1.1);
}
</style>
