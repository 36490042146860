import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useConfirmStore = defineStore(
  'confirm',
  () => {
    const title = ref('')
    const message = ref('')
    const show = ref(false)
    const confirm = ref(() => {})
    const showCancel = ref(true)
    const cancel = ref(() => {
      show.value = false
      showCancel.value = true
    })

    const showConfirmDialog = (titleText, messageText, confirmFn, cancelFn = false) => {
      title.value = titleText
      message.value = messageText
      confirm.value = () => {
        show.value = false
        confirmFn?.()
      }
      show.value = true
      if (cancelFn) {
        cancel.value = cancelFn
      }
    }

    return {
      title,
      message,
      show,
      showConfirmDialog,
      confirm,
      cancel,
      showCancel,
    }
  },
  {},
)
