<script setup>
import { defineProps, defineEmits } from 'vue'
import UiLoading2 from '@/components/UiLoading2.vue'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
})

const emit = defineEmits(['click'])

const handleClick = (e) => {
  if (!props.disabled && !props.loading) {
    emit('click', e)
  }
}
</script>

<template>
  <button :type="type" :class="['btn-yellow', { disabled, loading }]" @click="handleClick">
    <slot />
    <ui-loading2 v-if="loading" />
  </button>
</template>

<style scoped>
.btn-yellow {
  position: relative;
  display: flex;
  padding: 4px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  cursor: pointer;

  border-radius: 100px;
  background-color: #ffd600;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #002a48;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  transition: all 0.2s ease;

  &:active {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.loading {
    cursor: wait;
    pointer-events: none;
  }
}
</style>
