<template>
  <Teleport to="body">
    <div v-if="visible" class="message-must-dialog-mask">
      <div class="message-must-dialog">
        <div class="message-must-dialog-header">
          <div class="message-must-dialog-title">{{ msg?.title }}</div>
          <div class="message-must-dialog-time">{{ formatTime(msg?.ts) }}</div>
        </div>

        <div class="message-must-dialog-content" v-html="msg?.content"></div>
        <div class="message-must-dialog-footer">
          <ui-button-blue @click="onRead" :loading="requesting">
            {{ $t('noticeRead') }}
          </ui-button-blue>
          <!-- <button class="message-must-dialog-btn">
          </button> -->
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { defineProps, defineEmits, defineModel, ref, defineExpose } from 'vue'
import { formatDateTime } from '@/utils/utils.js'
import { readMessage } from '@/api/messages.js'
import UiButtonBlue from '@/components/ui-button-blue.vue'

const visible = ref(false)
const msg = ref(null)

const props = defineProps({
  getNextMessage: {
    type: Function,
    default: () => {},
  },
})

function show() {
  showNext()
}

defineExpose({
  show,
})

function showNext() {
  msg.value = props.getNextMessage()
  visible.value = msg.value !== undefined && msg.value !== null
}

const requesting = ref(false)
function onRead() {
  if (requesting.value) return
  requesting.value = true
  readMessage(msg.value.id)
    .finally(() => {
      showNext()
    })
    .finally(() => {
      requesting.value = false
    })
}

// 格式化时间
function formatTime(timestamp) {
  return timestamp ? formatDateTime(timestamp * 1000) : ''
}
</script>

<style scoped>
.message-must-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  z-index: 99999;
}

.message-must-dialog {
  background: #004577;
  border-radius: 8px;
  min-width: 400px;
  max-width: 80vw;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.message-must-dialog-header {
  padding: 16px 24px 10px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-must-dialog-title {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.message-must-dialog-time {
  color: #9fa2ab;
  font-size: 14px;
}

.message-must-dialog-content {
  padding: 14px 24px 14px 24px;
  color: #fff;
  overflow: auto;
  max-height: calc(60vh - 140px);
  line-height: 1.6;
}

.message-must-dialog-footer {
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.message-must-dialog-btn {
  background: #8eb50d;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100px;
  transition: background-color 0.3s;
}

.message-must-dialog-btn:hover {
  background: #789a08;
}

/* 自定义滚动条样式 */
.message-must-dialog-content::-webkit-scrollbar {
  width: 6px;
}

.message-must-dialog-content::-webkit-scrollbar-track {
  background: #3d404b;
  border-radius: 3px;
}

.message-must-dialog-content::-webkit-scrollbar-thumb {
  background: #8eb50d;
  border-radius: 3px;
}

.message-must-dialog-content::-webkit-scrollbar-thumb:hover {
  background: #789a08;
}
</style>
